<template>
    <v-container fluid>
        <h1>Gestão de Equipamentos</h1>

        <v-tabs v-model="tab">
            <v-tab>Equipamento</v-tab>
            <v-tab>Controle</v-tab>
            <v-tab>Histórico</v-tab>
            <v-tab>Manutenção</v-tab>
            <v-tab>Consulta por paciente</v-tab>
            <v-tab>Tabela de preço</v-tab>
            <v-tab>Consulta por almoxarifado</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <Equipamentos/>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>
import '../../assets/css/styles.css'

export default {
    name: 'GestaoEquipamento',
    components: {
        Equipamentos: () => import("./Equipamentos.vue")
    },
    data: () => ({
        tab: 0,
    })
}
</script>